<template>
  <div class="rse" :class="{ equality: equalitySection }">
    <SectionTitle v-if="equalitySection" class="title-section"
      >Index égalité hommes / femmes
    </SectionTitle>
    <div v-else class="header">Pourquoi partir avec nous ?</div>
    <div class="responsabilities">
      <div
        v-for="responsability of equalitySection ? equalitySection : common?.responsabilities"
        :key="responsability.title"
        :class="{ responsability, clickable: responsability.url }"
      >
        <NuxtLink :to="responsability.url">
          <div class="icon-stack">
            <Icon
              v-if="responsability.icon"
              class="icon"
              :name="responsability.icon"
              fill="var(--color-primary-black)"
            />
          </div>
          <div class="title">{{ responsability.title }}</div>
          <div class="description">{{ responsability.description }}</div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ResponsabilityWithoutID } from '@/lib/types/models/common';

defineProps<{
  equalitySection?: ResponsabilityWithoutID[] | undefined;
}>();
const rootStore = useRootStore();
const { common } = storeToRefs(rootStore);
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/mouse.scss';

.rse {
  flex-direction: column;
  gap: 40px;
  padding: 60px 40px;
  background-color: colors.$primary-yellow;

  @include breakpoints.mobile() {
    gap: 32px;
    padding: 40px metrics.$mobile-inner-margin;
  }

  &.equality {
    background-color: white;

    .responsabilities {
      .responsability {
        .title {
          height: 30px;
          color: colors.$primary-red;
        }
      }
    }

    .title-section {
      width: metrics.$inner-width;
      margin: auto;
    }
  }

  .header {
    justify-content: center;
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;

    @include breakpoints.mobile() {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .responsabilities {
    justify-content: space-evenly;

    @include breakpoints.mobile() {
      display: grid;
      grid-template-columns: repeat(3, auto);
      row-gap: 24px;
    }

    .clickable {
      @include mouse.clickable-opacity(0.6, -0.2);
    }

    .responsability {
      flex-direction: column;
      gap: 5px;
      align-items: center;

      width: 160px;
      height: 233px;

      @include breakpoints.mobile() {
        width: 110px;
        height: 151px;
      }

      .icon {
        width: 72px;
        height: 78px;

        @include breakpoints.mobile() {
          width: 44px;
          height: 48px;
        }
      }

      .icon-stack {
        justify-content: center;
      }

      .title {
        justify-content: center;

        height: 58px;
        margin-top: 16px;
        margin-bottom: 6px;

        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        text-align: center;

        @include breakpoints.mobile() {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .description {
        line-height: 19px;
        text-align: center;

        @include breakpoints.mobile() {
          font-size: 11px;
          line-height: 13px;
        }
      }
    }
  }
}
</style>
